import { Options, Vue } from "vue-class-component";

import {
  DynamicTable,
  MyAutocomplete,
  ServiceDuration
} from "@components";

import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { authStore } from "@/modules/auth/store";
import Dialog from "primevue/dialog";
import { FilterMatchMode } from "primevue/api";
import { posServiceService } from "@services/posService.service";
import { POSRoutesEnum } from "../../router";
import { POSService } from "@/model/api/POS";

@Options({
  components: {
    DynamicTable,
    Dialog,
    MyAutocomplete,
    ServiceDuration
  }
})
export default class POSServicesListPage extends Vue {
  readonly stateTableKey = 'POSServicesListPage';

  /*allServices: Service[] = [];
  filteredCategories: ServiceCategory[] = [];*/

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters: any = this.getNewFiltersSchema();

  /*get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('generic.published'), value: true },
      { name: this.$t('generic.not_published'), value: false },
    ]
  }*/

  created() {
    //this.filteredCategories = [{ id: null, name: this.$t('all_plur_fem_label') }];
  }

  beforeRouteLeave() {
    if (posServiceService?.cancelPendingRequests) {
      posServiceService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(POSRoutesEnum.SERVICES_LIST)) {
      sessionStorage.removeItem('POSServicesListPage');
    }
    next();
  }

  get me() {
    return authStore.getters.me;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  goAdd() {
    this.$router.push({ name: POSRoutesEnum.ADD_SERVICE });
  }

  get service() {
    return posServiceService;
  }

  /*get filterServiceCategory() {
    return this.filteredCategories.find(c => c.id == this.filters.service_category_id.value && c.id);
  }*/

  goToDetail(model: POSService) {
    this.$router.push({ name: POSRoutesEnum.EDIT_SERVICE, params: { id: model.id } });
  }

  customIndexRequest(p) {
    return posServiceService.indexByPOS(this.me.entity_id, p);
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    return [
      {
        field: 'service_name',
        header: this.$t('generic.input_name_label')
      },
      /*{
        field: 'service_categories_text',
        header: this.$t('provider.portfolio.detail.service_categories_label'),
        filterField: 'service_category_id'
      },*/
      {
        field: 'duration',
        header: this.$t('pos.services.list.duration_col_label'),
        style: 'text-align: right; min-width: 10px; max-width: 80px;'
      },
      /*{
        field: 'is_active',
        header: this.$t('generic.input_state_label')
      }*/
    ];
  }

  getNewFiltersSchema() {
    return {
      service_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      /*service_category_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },*/
      duration: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      is_active: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }
}

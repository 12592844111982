import { Options, Vue } from "vue-class-component";

import {
  DynamicTable,
  MyAutocomplete
} from "@components";

import { FilterMatchMode } from "primevue/api";
import { POSRoutesEnum } from "../../router";
import { POSPortfolio } from "@/model/api/POS";
import { posPortfolioService } from "@services/posPortfolio.service";
import { authStore } from "@/modules/auth/store";
import moment from "moment";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})

export default class POSCustomerFeedbackPage extends Vue {
  readonly stateTableKey = 'POSCustomerFeedbackPage';
  filters: any = this.getNewFiltersSchema();
  isTableLoading = false;
  totalRecordsLength = 0;
  reloadCount = 0;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (posPortfolioService?.cancelPendingRequests) {
      posPortfolioService.cancelPendingRequests();
    }
  }

  get service() {
    return posPortfolioService;
  }

  get me() {
    return authStore.getters.me;
  }

  groupByDate() {
    this.datatable.groupByDate = '';
  }
  
  get entityId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }

  customIndexRequest(p) {    
    this.reloadCount++;
    if (this.me.role == UserRoleEnum.SUPER_ADMIN)
      return posPortfolioService.indexByPOS(0, p);
    else
      return posPortfolioService.indexByPOS(this.me.entity_id, p);
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    let columns = [
      {
        field: 'service_name',
        header: this.$t('generic.input_name_label'),
        filterField: 'service_name',
        style: 'min-width: 200px;'
      },
      // {
      //   field: 'service_categories_text',
      //   header: this.$t('pos.portfolio.detail.service_categories_label'),
      //   filterField: 'service_category_id'
      // },
      {
        field: 'provider_owner_name',
        header: this.$t('pos.portfolio.detail.provider_owner_name_label'),
        filterField: 'provider_owner_id',
        style: 'min-width: 10px; max-width: 150px;'
      },
      {
        field: 'service_date_at',
        header: this.$t('pos.portfolio.detail.service_date_label'),
        filterField: 'service_date_at',
        style: 'min-width: 10px; max-width: 120px;'
      },
      {
        field: 'gross_price',
        header: this.$t('pos.portfolio.detail.selling_price_label'),
        filterField: 'gross_price',
        style: 'text-align: right; min-width: 10px; max-width: 100px;',
        headerColspan: 2
      },
      {
        field: 'feedback_service',
        header: this.$t('generic.input_feedback_label'),
        filterField: 'feedback_service',
        style: 'min-width: 200px;'
      }
    ];
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      const posCol = [{
        field: 'pos_owner_name',
        header: this.$t('orders.table.pos'),
        filterField: 'entity_id',
        style: 'min-width: 10px; max-width: 150px;'
      }];

      columns = posCol.concat(columns);
    }
    return columns;
  }

  getNewFiltersSchema() {
    return {
      entity_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      service_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      service_category_id: {
        style: 'min-width: 10px; max-width: 150px',
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      provider_owner_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      service_date_at: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      gross_price: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  get serviceDateAt(): Date {
    const m = moment(this.filters.service_date_at.value, true);
    return m.isValid() ? m.toDate() : null;
  }

  set serviceDateAt(value) {
    const m = moment(value, true);
    this.filters.service_date_at.value = m.isValid() ? m.format('YYYY-MM-DD') : value;
  }

  onTableLoadingChange(value: boolean) {
    this.isTableLoading = value;    
  }

  onTableTotalRecordsChange(value: number) {
    this.totalRecordsLength = value;
  }
}

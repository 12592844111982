import { Options, Vue } from "vue-class-component";

import { authStore } from "@/modules/auth/store";
import { Prop } from "vue-property-decorator";
import { POSAddServiceStepEnum } from "../../router/POSRoutesEnum";
import { POSService } from "@/model/api/POS";

import ChooseServiceStep from "./add_steps/ChooseServiceStep.vue";
import AttachmentsStep from "./add_steps/AttachmentsStep.vue";
import SummaryStep from "./add_steps/SummaryStep.vue";
import { posServiceService } from "@services/posService.service";
import { FormPage } from "@components";

@Options({
  components: {
    ChooseServiceStep,
    AttachmentsStep,
    SummaryStep
  }
})
export default class POSAddServicePage extends FormPage {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly id?: number;

  dataModel: POSService = new POSService();
  isValidTimeline = false;

  protected modelFormName = 'dataModel';

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  get me() {
    return authStore.getters.me;
  }

  get currentTimelineIndex() {
    switch (this.step) {
      case POSAddServiceStepEnum.CHOOSE_SERVICE:
        return 0;
      case POSAddServiceStepEnum.ATTACHMENTS:
        return 1;
      case POSAddServiceStepEnum.SUMMARY:
        return 2;
    }
    return -1; // hide timeline
  }

  async created() {
    // first step
    if (this.step != POSAddServiceStepEnum.CHOOSE_SERVICE) {
      this.goStep(POSAddServiceStepEnum.CHOOSE_SERVICE);
    }

    if (this.id) {
      this.dataModel = await posServiceService.getById(this.id);
    }

    this.modelFormChanged();

    this.isValidTimeline = true;
  }

  async goStep(step: POSAddServiceStepEnum) {
    await this.$router.replace({ name: this.$route.name, params: { step: step } });
  }

  onModelFormChanged() {
    this.modelFormChanged();
  }
  
}

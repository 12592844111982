import { POSService } from "@/model/api/POS";
import { AttachmentTypeEnum } from "@/model/enums/AttachmentTypeEnum";
import { authStore } from "@/modules/auth/store";
import { POSAddServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { POSRoutesEnum } from '@/modules/pos/router';
import { posServiceService } from "@services/posService.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep', 'modelFormChanged']
})
export default class SummaryStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSService;

  goBack() {
    this.$emit('goStep', POSAddServiceStepEnum.ATTACHMENTS);
  }

  async save() {
    this.dataModel.entity_id = authStore.getters.me.entity_id;
    this.dataModel.service_id = this.dataModel.service.id;
    this.dataModel.is_active = true;

    await this.$waitFor(async () => {
      let posService = null;
      if (this.dataModel.id) {
        posService = await posServiceService.updatePatch(this.dataModel);
      } else {
        posService = await posServiceService.create(this.dataModel);
      }

      const newAttachs = this.dataModel.attachments.filter(a => a._file);
      for (const attach of newAttachs) {
        await posServiceService.uploadAttachment(posService.id, attach._file, attach.description, attach.type);
      }

      this.$successMessage(this.$t('data_saved_success'));
      this.$emit('modelFormChanged');

      this.$router.push({ name: POSRoutesEnum.SERVICES_LIST });
    });
  }
}
